import './src/styles/global.css';
import '@fontsource/lato/latin-ext-400.css';
import '@fontsource/lato/latin-ext-700.css';
import '@fontsource/lato/latin-ext-900.css';
import '@fontsource/lato/latin-400.css';
import '@fontsource/lato/latin-700.css';
import '@fontsource/lato/latin-900.css';
import ReactDOM from "react-dom/client";

export const replaceHydrateFunction = () => {
    return (element, container) => {
      const root = ReactDOM.createRoot(container)
      root.render(element)
    }
  }